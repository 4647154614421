var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "anonaddy-wrapper" }, [
    _vm.meta && !_vm.hideMeta
      ? _c(
          "div",
          { staticClass: "account-info" },
          [
            _c("PercentageChart", {
              attrs: {
                title: "Mail Stats",
                values: [
                  {
                    label: "Forwarded",
                    size: _vm.meta.forwardCount,
                    color: "#20e253",
                  },
                  {
                    label: "Blocked",
                    size: _vm.meta.blockedCount,
                    color: "#f80363",
                  },
                  {
                    label: "Replies",
                    size: _vm.meta.repliesCount,
                    color: "#04e4f4",
                  },
                  { label: "Sent", size: _vm.meta.sentCount, color: "#f6f000" },
                ],
              },
            }),
            _c("div", { staticClass: "meta-item" }, [
              _c("span", { staticClass: "lbl" }, [_vm._v("Bandwidth")]),
              _c("span", { staticClass: "val" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("formatBytes")(_vm.meta.bandwidth)) +
                    " out of " +
                    _vm._s(
                      _vm.meta.bandwidthLimit !== 100000000
                        ? _vm.formatBytes(_vm.meta.bandwidthLimit)
                        : "∞"
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "meta-item" }, [
              _c("span", { staticClass: "lbl" }, [_vm._v("Active Domains")]),
              _c("span", { staticClass: "val" }, [
                _vm._v(
                  _vm._s(_vm.meta.activeDomains) +
                    " out of " +
                    _vm._s(_vm.meta.activeDomainsLimit)
                ),
              ]),
            ]),
            _c("div", { staticClass: "meta-item" }, [
              _c("span", { staticClass: "lbl" }, [_vm._v("Shared Domains")]),
              _c("span", { staticClass: "val" }, [
                _vm._v(
                  _vm._s(_vm.meta.sharedDomains) +
                    " out of " +
                    _vm._s(_vm.meta.sharedDomainsLimit || "∞")
                ),
              ]),
            ]),
            _c("div", { staticClass: "meta-item" }, [
              _c("span", { staticClass: "lbl" }, [_vm._v("Usernames")]),
              _c("span", { staticClass: "val" }, [
                _vm._v(
                  _vm._s(_vm.meta.usernamesCount) +
                    " out of " +
                    _vm._s(_vm.meta.usernamesLimit || "∞")
                ),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.aliases && !_vm.hideAliases
      ? _c(
          "div",
          { staticClass: "email-list" },
          _vm._l(_vm.aliases, function (alias) {
            return _c("div", { key: alias.id, staticClass: "email-row" }, [
              _c(
                "div",
                { staticClass: "row-1" },
                [
                  !_vm.disableControls
                    ? _c("Toggle", {
                        attrs: {
                          defaultState: alias.active,
                          id: alias.id,
                          hideLabels: true,
                        },
                        on: { change: _vm.toggleAlias },
                      })
                    : _vm._e(),
                  _vm.disableControls
                    ? _c(
                        "span",
                        {
                          class: `status ${
                            alias.active ? "active" : "inactive"
                          }`,
                        },
                        [_vm._v("●")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "address-copy",
                      attrs: { title: "Click to Copy" },
                      on: {
                        click: function ($event) {
                          return _vm.copyToClipboard(alias.fullEmail)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "txt-email" }, [
                        _vm._v(_vm._s(alias.email)),
                      ]),
                      _c("span", { staticClass: "txt-at" }, [_vm._v("@")]),
                      _c("span", { staticClass: "txt-domain" }, [
                        _vm._v(_vm._s(alias.domain)),
                      ]),
                    ]
                  ),
                  _c("ClipboardIcon", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.tooltip("Copy alias to clipboard"),
                        expression: "tooltip('Copy alias to clipboard')",
                      },
                    ],
                    staticClass: "copy-btn",
                    on: {
                      click: function ($event) {
                        return _vm.copyToClipboard(alias.fullEmail)
                      },
                    },
                  }),
                ],
                1
              ),
              alias.description
                ? _c("div", { staticClass: "row-2" }, [
                    _c("span", { staticClass: "description" }, [
                      _vm._v(_vm._s(alias.description)),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row-3" }, [
                _c("span", { staticClass: "lbl" }, [_vm._v("Forwarded")]),
                _c("span", { staticClass: "val" }, [
                  _vm._v(_vm._s(alias.forwardCount)),
                ]),
                _c("span", { staticClass: "lbl" }, [_vm._v("Blocked")]),
                _c("span", { staticClass: "val" }, [
                  _vm._v(_vm._s(alias.blockedCount)),
                ]),
                _c("span", { staticClass: "lbl" }, [_vm._v("Replied")]),
                _c("span", { staticClass: "val" }, [
                  _vm._v(_vm._s(alias.repliesCount)),
                ]),
                _c("span", { staticClass: "lbl" }, [_vm._v("Sent")]),
                _c("span", { staticClass: "val" }, [
                  _vm._v(_vm._s(alias.sentCount)),
                ]),
              ]),
              _c("div", { staticClass: "row-4" }, [
                _c("span", { staticClass: "lbl" }, [_vm._v("Created")]),
                _c("span", { staticClass: "val as-date" }, [
                  _vm._v(_vm._s(_vm._f("formatDate")(alias.createdAt))),
                ]),
                _c("span", { staticClass: "val as-time-ago" }, [
                  _vm._v(_vm._s(_vm._f("formatTimeAgo")(alias.createdAt))),
                ]),
              ]),
            ])
          }),
          0
        )
      : _vm._e(),
    _vm.numPages && !_vm.hideAliases
      ? _c(
          "div",
          { staticClass: "pagination" },
          [
            _c(
              "span",
              {
                staticClass: "page-num first",
                on: {
                  click: function ($event) {
                    return _vm.goToFirst()
                  },
                },
              },
              [_vm._v("«")]
            ),
            _vm.paginationRange[0] !== 1
              ? _c(
                  "span",
                  {
                    staticClass: "page-num",
                    on: {
                      click: function ($event) {
                        return _vm.goToPrevious()
                      },
                    },
                  },
                  [_vm._v("...")]
                )
              : _vm._e(),
            _vm._l(_vm.paginationRange, function (pageNum) {
              return _c(
                "span",
                {
                  key: pageNum,
                  class: `page-num ${
                    pageNum === _vm.currentPage ? "selected" : ""
                  }`,
                  on: {
                    click: function ($event) {
                      return _vm.goToPage(pageNum)
                    },
                  },
                },
                [_vm._v(_vm._s(pageNum))]
              )
            }),
            _vm.paginationRange[_vm.paginationRange.length - 1] < _vm.numPages
              ? _c(
                  "span",
                  {
                    staticClass: "page-num",
                    on: {
                      click: function ($event) {
                        return _vm.goToNext()
                      },
                    },
                  },
                  [_vm._v("...")]
                )
              : _vm._e(),
            _c(
              "span",
              {
                staticClass: "page-num last",
                on: {
                  click: function ($event) {
                    return _vm.goToLast()
                  },
                },
              },
              [_vm._v("»")]
            ),
            _c("p", { staticClass: "page-status" }, [
              _vm._v(
                "Page " +
                  _vm._s(_vm.currentPage) +
                  " of " +
                  _vm._s(_vm.numPages)
              ),
            ]),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }