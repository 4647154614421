var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "toggle-switch",
      class: { active: _vm.isActive },
      attrs: { for: _vm.id + "_button" },
    },
    [
      !_vm.hideLabels
        ? _c("span", { staticClass: "toggle__label" }, [
            _vm._v(_vm._s(_vm.isActive ? _vm.enableText : _vm.disabledText)),
          ])
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checkedValue,
            expression: "checkedValue",
          },
        ],
        attrs: {
          type: "checkbox",
          disabled: _vm.disabled,
          id: _vm.id + "_button",
        },
        domProps: {
          checked: Array.isArray(_vm.checkedValue)
            ? _vm._i(_vm.checkedValue, null) > -1
            : _vm.checkedValue,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.checkedValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checkedValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checkedValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checkedValue = $$c
            }
          },
        },
      }),
      _c("span", { staticClass: "switch" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }